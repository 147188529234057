import axios from "axios";
import { env as environment } from "../environments";


export const s3fileHandler = async (file,sessionId) => {

  try {
    const formData = new FormData();
    formData.append("filename", sessionId + "-" + new Date().getTime().toString() + ".pdf");
    formData.append("file", file, sessionId + "-" + new Date().getTime().toString() + ".pdf");
  
    await axios.post(environment.s3Upload, formData);    
   }catch (err) {
      console.error(`Error in s3File upload`, err.message);
      throw err;
    }
}
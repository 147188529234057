import HomePage from "../pages/index";
import Wrapper from "../pages/Wrapper";
import { env as environment } from "../environments";

const queryParams = new URLSearchParams(window.location.search);
const newParams = {};

for (const [key, value] of queryParams) {
  newParams[key] = value;
}

let homePageComponent;

if (environment["env"] === "CDN") {
  homePageComponent = Wrapper;
} else {
  if (newParams.hasOwnProperty('buttonid')) {
    homePageComponent = Wrapper;
  } else {
    homePageComponent = HomePage;
  }
}

const indexRoutes = [{ path: "/", name: "Wrapper", page: homePageComponent }];

export default indexRoutes;

// App.js
import React from 'react';
import { Provider } from 'react-redux';
import {configureStore} from './store/configureStore';
import Router from './routes/router';
import "./App.css"

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        {/* <HeaderComponent />
        <Container style={{height: "32rem"}}><UploadFile /></Container> */} 
        <Router/>
      </div>
    </Provider>
  );
}

export default App;
